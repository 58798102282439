$navBreak: "only screen and (max-width : 991px)";

// HEADER
header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  background: $white;
}

// .headerDark{
//   background: transparent;
// }

// HAMBURGER ICON
.navbar-toggler {
  background: none;
  border: none;
  &:active,
  &:focus {
    outline: 0;
  }
  .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    margin: 4px 0 4px 0;
    transition: all 0.2s;
    background-color: $white;
  }
}

.navbar-toggler {
  .icon-bar {
    &:nth-of-type(1) {
      transform: rotate(0);
    }
    &:nth-of-type(2) {
      opacity: 1;
      filter: alpha(opacity = 100);
      width: 85%;
    }
    &:nth-of-type(3) {
      transform: rotate(0);
      width: 50%;
    }
  }
  &[aria-expanded="true"] .icon-bar {
    &:nth-of-type(1) {
      transform: rotate(45deg);
      transform-origin: 10% 10%;
    }
    &:nth-of-type(2) {
      opacity: 0;
      filter: alpha(opacity = 0);
      width: 100%;
    }
    &:nth-of-type(3) {
      transform: rotate(-45deg);
      transform-origin: 10% 90%;
      width: 100%;
    }
  }
}

/* NAVBAR DEFAULT */

.defaultHeader {
  transition: background-color ease-in-out 250ms, color ease-in-out 250ms;
  padding: 1em 0;
  @media #{$navBreak} {
    padding: 0;

    .navbar-toggler {
      .icon-bar {
        background-color: $dark;
      }
    }
  }

  .navbar-brand {
    width: 238px;
    height: 100px;
    padding-top: 5px;
    background-image: url(/wp-content/uploads/2020/08/My-Town-Nuneaton-identity-01.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center left;
    @media #{$navBreak} {
      width: 119px;
      height: 26;
    }
  }

  .mainNav {
    padding-right: 0;
    padding-left: 0;
    .navbar-nav {
      .nav-item {
        & + .nav-item {
          margin-left: 50px;
          @media #{$navBreak} {
            margin-left: 0;
          }
        }
        .nav-link {
          font-size: 18px;
          font-weight: 700;
          color: $dark;
          text-transform: uppercase;
          position: relative;
          transition: opacity ease-in-out 250ms;

          &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 5px;
            width: calc(100% - 1rem);
            margin-left: 0.5rem;
            height: 4px;
            background: currentColor;

            transform: scaleX(0);
            transform-origin: right;
            transition: transform 250ms ease-in-out;
            @media #{$navBreak} {
              display: none;
            }
          }

          &:hover,
          &:focus,
          &.active {
            opacity: 0.8;
            transition: opacity ease-in-out 250ms;

            &:after {
              transform: scaleX(1);
              transform-origin: left;
              transition: transform 250ms ease-in-out;
            }
          }
        }
      }
    }

    /* =================================== */
    /* =================================== */
    /* =================================== */
    &__social {
      display: flex;
      justify-content: space-between;
      min-width: 100px;
    }
  
    .socialNav {
      @media #{$navBreak} {
        position: fixed;
        bottom: 1em;
        right: 1em;
        width: 100px;
        z-index: 2;
        // min-height: 100px;
        // flex-direction: column;
        justify-content: space-around;
        opacity: 0;
        pointer-events: none;
        
        transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
      }

      &Show{
        opacity: 1;
        pointer-events: unset;
      }
    }

    &__socialLink {
      color: $dark;
      background-color: $white;
      width: 40px;
      height: 40px;
      text-align: center;
      border-radius: 50%;
      font-size: 1.3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
      transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
      &:hover {
        text-decoration: none;
        color: $white;
        transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
      }

      &--instagram {
        position: relative;
        overflow: hidden;
        background-color: transparent;
        i {
          z-index: 4;
        }
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          transition: opacity 200ms ease-in-out;
        }
        &::before {
          background: #d6249f;
          background: radial-gradient(
            circle at 30% 107%,
            #fdf497 0%,
            #fdf497 5%,
            #fd5949 45%,
            #d6249f 60%,
            #285aeb 90%
          );
        }
        &::after {
          background: $white;
        }
      }

      &--instagram:hover::after {
        opacity: 0;
        transition: opacity 200ms ease-in-out;
      }
      &--facebook:hover {
        background-color: #3b5998;
      }
      &--twitter:hover {
        background-color: #00acee;
      }
      &--youtube:hover {
        background-color: #c4302b;
      }
    }
    /* =================================== */
    /* =================================== */
    /* =================================== */
  }
}

// NAVBAR SCROLL UP
.header-fade-out {
  box-shadow: 0 0 20px rgba($black, 0.1);
  animation: slide-bottom 0.5s ease-in-out;
}
.header-fade-in {
  box-shadow: 0 0 20px rgba($black, 0.1);
  animation: slide-top 0.5s ease-in-out reverse;
}

// NAVBAR DARK
.headerDark {
  background-color: $light;

  .navbar-toggler {
    .icon-bar {
      background-color: $dark;
    }
  }

  .navbar-brand {
    background-image: url(/wp-content/uploads/2020/08/My-Town-Nuneaton-identity-01.png);
    height: 100px;
    padding-top: 5px;
    // opacity: 0;
  }

  .mainNav .navbar-nav .nav-item .nav-link {
    color: $dark;
  }
}

// NAVBAR LIGHT
.headerLight {
  background-color: $white;

  .navbar-toggler {
    .icon-bar {
      background-color: $dark;
    }
  }
  .navbar-brand {
    background-image: url(/wp-content/uploads/2020/08/My-Town-Nuneaton-identity-01.png);
    height: 100px;
    padding-top: 5px;
  }

  .mainNav .navbar-nav .nav-item .nav-link {
    color: $dark;
  }
}

// NAV BREAK
@media #{$navBreak} {
  .navBreak {
    background-color: $dark;
    min-height: 100vh;
    transition: height ease-in-out 255ms;
    // position: relative;
    overflow: hidden;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 100%;
      height: 100vh;
      width: 100%;
      background-color: $primary;
      transform: skewY(-20deg);
      margin-top: -18.5vw;
    }

    .navbar-brand {
      background-image: url(/wp-content/uploads/2020/08/My-Town-Nuneaton-identity-01.png);
      height: 100px;
      padding-top: 5px;
    }

    .navbar-toggler {
      .icon-bar {
        background-color: $white;
      }
    }
  }

  .defaultHeader {
    .mainNav {
      .navbar-nav {
        justify-content: center;
        align-items: center;
        height: calc(100vh - 250px);
        .nav-item {
          .nav-link {
            color: $white;
          }
        }
      }
    }
  }
}
