html {
  height: 100%;
  overflow-x: hidden;
}

body {
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  color: $dark;
  padding-top: 99.97px;
  @media only screen and (max-width: 991px) {
  padding-top: 68px;
  }

  &.noScroll {
    overflow: hidden;
    height: 100%;
  }
}

footer {
  margin-top: auto;
}

a{
  color: $primary;
  &:hover{
    color: $primary;
  }
}

/* ================== */
/* ================== */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

.small-header {
  font-size: 24px;
}

.smaller-header {
  font-size: 18px;
}

.smallest-header {
  font-size: 14px;
  font-weight: 400;
}

.date-text {
  color: $secondary;
}

/* ====================================
Breadcrum
==================================== */
.siteMap {
  background-color: $light;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  font-size: 14px;
}

/* ====================================
BUTTON
==================================== */

.btn {
  &__clip {
    border-radius: 0;
    background-color: transparent;
    border: none;
    position: relative;
    min-width: 120px;
    min-height: 40px;

    &:hover {
      background-color: transparent;
    }
  }

  .svgBtn {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;


    @media not all and (min-resolution:.001dpcm) { 
      @supports (-webkit-appearance:none) {
        @media (max-width: 768px){
          width: 120%;
        }
      }
 }

    &__group-1 {
      transform: translate(0);
      transition: transform 200ms ease-in-out;
    }

    &__text {
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -30%);
      width: 100%;
      color: $white;
      font-weight: 600;
    }
  }

  &:hover {
    .svgBtn {
      &__group-1 {
        transform: translate(5px, -8px);
      }
    }
  }

  &__gray {
    .svgBtn {
      &__group-1 {
        transform: translate(5px, -8px);
        transition: transform 200ms ease-in-out;
        fill: $primary;
      }
      &__group-2 {
        fill: darken($light, 10%);
        stroke: darken($light, 10%);
      }

      &__text{
        color: $primary;
      }
    }

    &:hover{
      .svgBtn {
        &__group-1 {
          transform: translate(0);
        }
      }
    }
  }
}


/* THE CONTENT */
.theContent{
  background-color: $light;
  padding: 2em;
  margin-top: 2em;
  box-shadow: 0 0 10px rgba($dark, 0.3);
}