.footer {
  background-color: $black;
  color: $white;

  &__list{
    list-style: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 0;
    @media only screen and (max-width: 576px) {
    width: 100%;
    padding: 2em 0;
    }
    @media only screen and (max-width: 340px) {
      flex-direction: column;
      li{
        padding: 0.5em 0;
      }
    }
  }

  a{color: $white;}
}
