/*
Theme Name: Dimakin
Author: Saydur Rahman
Description: Custom Bootstrap 4 Woocomerce theme.
Version: 1.0

* Copyright © 2019 Dimakin All rights reserved. Code or files can not be copied, modified and/or distributed without the express written permission. Unauthorized copying, modification or use of this file, via any medium is strictly prohibited.
*/

// SWIPER
@import "~swiper/swiper";

/* BOOTSTRAP 4 */
@import "~bootstrap/scss/bootstrap";

// ANIMATION
@import "keyframe";

// VARS
@import "vars";

// CUSTOM
@import "global";
@import "modules/mainNav";
@import "page/pages";

