.blogPost {
  &__bg {
    min-height: 500px;
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 2em;
  }

  &__content {
    box-shadow: 0px 0px 50px rgba($black, 0.15);
  }

  &__text {
    padding: 1em;
  }
}

/* =====================
  SIDE BAR 
  ======================*/
.latestPost {
  @media (max-width: 1200px){
    padding-top: 30px;
  }

  &__inner {
    @media only screen and (max-width: 768px) {
      background-color: $light;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;

      padding: 2em;

      width: 100vw;
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);
    }
  }
}
.avatar {
  display: flex;
  align-items: center;
  border-bottom: 2px solid $light;
  color: $black;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;

  &:hover {
    text-decoration: none;
    color: $secondary;
  }
  @media only screen and (max-width: 768px) {
    width: 50%;
    border: 1px solid $white;
    padding: 1em;
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    text-align: center;
  }
  @media only screen and (max-width: 340px) {
    width: 100%;
    margin-bottom: 1em;
  }

  &__img {
    width: 50px;
    height: 50px;
    border-radius: 50%;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    // @media only screen and (max-width: 767px) {
    //   border-radius: 0;
    //   width: 100%;
    //   height: 300px;
    // }
  }

  &__text {
    width: calc(100% - 50px);
    padding-left: 1em;
    .date-text {
      margin-bottom: 0;
    }
    @media only screen and (max-width: 767px) {
      width: 100%;
      padding-left: 0;
    }
  }
}

/* SIDE BAR TAGS */
.tagged-posts {
  // display: flex;
  // justify-content: space-between;
  // flex-wrap: wrap;
  &__tags {
    background-color: $light;
    margin: 5px 0;
    text-transform: uppercase;
    color: $secondary;
    font-size: 12px;
    font-weight: 600;
  }
}

/* =================================== */
/* =================================== */
/* =================================== */

.btnWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 2em 0;
}

/* ===========================
IE 11
================================*/

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .grid {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
  }
}

.blogImage {
  img {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
  }
}

.grid {
  columns: auto 2;
  column-gap: 30px;

  @media (max-width: 768px){
    columns: auto 1;
  }

  .blogPost__item {
    display: inline-block;
    margin-bottom: 30px;
  }
}