.hero {
  height: 580px;
  background: $light;
  @media only screen and (max-width: 600px) {
  height: 300px;
  padding: 1em;
  padding-right: 1em;
  overflow: hidden;
  }

  &__img {
    opacity: 0.4;
  }

  &__img-logo {
    position: absolute;
    width: 525px;
    z-index: 1;

    @media (max-width: 767px){
      width: 180px;
      margin-top: 10px;
    }

    @media (min-width: 1200px){
      margin-top: 50px;
    }

  }
}

.home{
    .hero{
        background: $light;
        padding-bottom: 100px;
        overflow: hidden;

    }
}
/* ================================== */
/* ================================== */
/* ================================== */
/* ================================== */
